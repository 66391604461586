import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import { Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { fileURL, numPages } = state || {};

  // Función para realizar el login llamando al Lambda
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const payload = {
      email,
      password,
    };

    try {
      // Realizar la solicitud POST al endpoint de API Gateway que invoca tu Lambda
      const response = await fetch('https://1d2m9twpw4.execute-api.us-east-1.amazonaws.com/login_esp/cognito_access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Login successful');
       
        // Almacenar el email en localStorage
        localStorage.setItem('userEmail', email);

        // Redirigir a la página de PaymentGateway
        if (fileURL) {
          navigate('/PaymentGateway', { state: { email, fileURL, numPages } });
        } else {
          navigate('/PaymentGateway', { state: { email } });
        }
      } else {
        setError(result.message || 'Error al autenticar usuario');
      }
    } catch (error) {
      console.error('Login error', error);
      setError('Error al conectar con el servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="button" disabled={loading}>
          {loading ? 'Cargando...' : 'Iniciar sesión'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <br />
      <br />
      <p>
          ¿No tienes una cuenta? <Link to="/register">Regístrate</Link>
      </p>
      <p>
        <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
      </p>
    </div>
  );
}

export default Login;